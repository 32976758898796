<!-- #################################################################################### -->
<!-- ###### HERINCO                                                                ###### -->
<!-- ###### @author: John David Vásquez Serna                                      ###### -->
<!-- ###### @date: Noviembre 2023                                                  ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
    <v-main style="padding: 0 1rem !important;">

        <!-- Titulo del punto de venta y botón para crear una taquilla -->
        <div class="d-flex pt-3">
            <v-card-title class="me-2 fondoPuntoVenta"><span>{{ puntoDeVenta }} - {{ bodegaDelUsuario }}</span></v-card-title>
            
            <v-tooltip v-if="userRoles.includes(roles.Configuraciones_Administrativo_Taquillas_Administrar)" left color="success">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn small class="ms-2" fab color="success" v-bind="attrs" v-on="on" 
                        @mousedown.prevent="dialog = true">
                        <v-icon> add </v-icon>
                    </v-btn>
                </template>
                <span>Agregar</span>
            </v-tooltip>
        </div>

        <!-- dialogo para crear una taquilla -->
        <v-dialog v-model="dialog" persistent transition="dialog-bottom-transition" max-width="18rem">
            <v-card>
                <v-card-title class="fondoDialog">
                    <span class="text-h6">Crear taquilla</span>
                </v-card-title>

                <!-- formulario para crear una taquilla -->
                <v-card-text style="padding-top: 0.7rem;">
                    <validation-observer ref="observer" v-slot="{ invalid }">
                        <v-form class="mt-3" lazy-validation>
                            <!-- Campo para el código de la taquilla -->
                            <div class="d-flex">
                                <validation-provider v-slot="{ errors }" :rules="{ required: true, min: 3, tripleCero: true, primerDigito: true }">
                                    <v-text-field outlined id="codigoTaquilla" v-model="crearTaquilla.codigoTaquilla" maxlength="3"
                                        :error-messages="validarExistencia || errors" style="width: 13.5rem" dense label="Taquilla" 
                                        @keypress="validarLetra" @input="validarTaquilla()" @paste.prevent>
                                    </v-text-field>
                                </validation-provider>
                                <!-- Tooltip para mostrar información sobre el campo Taquilla -->
                                <div class="d-flex align-center tooltip">
                                    <v-tooltip right color="#1867c0">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon dark fab v-bind="attrs" v-on="on" color="#1867c0"> help </v-icon>
                                        </template>
                                        <span>Número de identificación de la taquilla
                                            <br>Está compuesto por tres dígitos.
                                            <br><em>Ejemplos: 001, 012</em>
                                        </span>
                                    </v-tooltip>
                                </div>
                            </div>
                           
                            <!-- Botones de cerrar y guardar que se activan cuando se abre el dialogo para crear una taquilla en base de datos-->
                            <div class="d-flex justify-end">
                                <v-btn color="error" text @click="reset()">
                                    CERRAR
                                </v-btn>
                                <v-btn depressed @click="guardarTaquilla()" :disabled="validacionActiva || invalid || !crearTaquilla.codigoTaquilla.trim()" color="success">
                                    GUARDAR
                                </v-btn>
                            </div>
                        </v-form>
                    </validation-observer>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- Tabla que muestra la lista de las taquillas registradas en base de datos -->
        <v-data-table :loading="loading" fixed-header :headers="headersTaquillas" :items-per-page="99" :items="listaDeTaquillas"
            class="elevation mt-4" height="66vh" hide-default-footer>
            <template v-slot:body="{ items }">
                <tbody>
                    <tr v-for="item in items" v-bind:key="item.id">
                        <td class="text-center">{{ item.codigoTaquilla }}</td>
                        <td>
                            <v-icon :color="item.eliminado === false ? 'success' : 'error'"> 
                                {{ item.eliminado === false ? 'check_circle' : 'cancel' }}
                            </v-icon>
                            {{ item.eliminado === false ? 'Activo' : 'Inactivo' }}
                        </td>
                        <td class="text-center">
                            <!-- Campo que permite abrir un dialog para cambiar el estado de una taquilla -->
                            <v-tooltip v-if="userRoles.includes(roles.Configuraciones_Administrativo_Taquillas_Administrar)" bottom :color="item.eliminado === false ? 'error' : 'success'">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon @click="abrirDialogoEstado(item)" v-bind="attrs" v-on="on">
                                        <v-icon :color="item.eliminado === false ? 'error' : 'success'">
                                            {{ item.eliminado === false ? 'person_add_disabled' : 'how_to_reg' }}
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ item.eliminado === false ? 'Inactivar' : 'Activar' }}</span>
                            </v-tooltip>
                            <!-- Campo que permite abrir un dialog para cambiar la relación del usuario a una taquilla -->
                            <v-tooltip v-if="userRoles.includes(roles.Configuraciones_Administrativo_Taquillas_Cambiar)" bottom :color="item.eliminado !== false && item.idUsta === null ? '#6d7072' : 'primary'">
                                <template v-slot:activator="{ on, attrs }">
                                    <button icon @mousedown.prevent="item.idUsta === null ? abrirDialogoTaquilla(item) : null" v-bind="attrs" v-on="on"
                                        :disabled="item.eliminado !== false && item.idUsta === null">
                                        <v-icon :color="item.eliminado !== false && item.idUsta === null ? '#6d7072' : 'primary'">
                                            {{ item.idUsta === null ? 'radio_button_unchecked' : 'radio_button_checked' }}
                                        </v-icon>
                                    </button>
                                </template>
                                <span v-if="item.eliminado !== false && item.idUsta === null">Active primero la taquilla</span>
                                <span v-else>{{ item.idUsta === null ? 'Seleccionar taquilla' : 'Taquilla actual' }}</span>
                            </v-tooltip>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-data-table>

        <!-- dialogo para cambiar el estado -->
        <v-dialog v-model="dialogoCambiarEstado" transition="dialog-bottom-transition" max-width="22.7rem" persistent>
            <v-card>
                <v-card-title class="fondoDialog">
                    <span class="text-h6">{{ mensajeEstado }}</span>
                </v-card-title>
                <v-card-text>
                    <div class="d-flex justify-end" style="padding-top: 1.3rem">
                        <v-btn color="error" text @click="dialogoCambiarEstado = false">No</v-btn>
                        <v-btn color="success" depressed text @click="cambiarEstado()">Si</v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- dialogo para cambiar la taquilla a la cual esta relacionado el usuario -->
        <v-dialog v-model="dialogoCambiarTaquilla" transition="dialog-bottom-transition" max-width="20rem" persistent>
        <v-card>
                <v-card-title class="fondoDialog">
                    <span class="text-h6">Cambio de caja</span>
                </v-card-title>
                <v-card-text class="pt-5 cambioCaja">
                    <span>{{ mensajeTaquilla }}</span>
                    <div class="d-flex justify-end" style="padding-top: 1.3rem">
                        <v-btn color="error" text @click="dialogoCambiarTaquilla = false">No</v-btn>
                        <v-btn color="success" depressed text @click="cambiarTaquilla()">Si</v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>

    </v-main>
</template>

<!-- #################################################################################### -->
<!-- ###### Sección de Scripts                                                     ###### -->
<!-- #################################################################################### -->
<script>
import { extend, ValidationObserver, ValidationProvider,  } from 'vee-validate';
import { mapMutations, mapState } from 'vuex';
import { Role } from '@/router/role.js';
import { required, min } from 'vee-validate/dist/rules';
//import Vue from "vue"

extend('required', {
    ...required,
    message: 'Este campo es requerido'
})
extend('min', {
    ...min,
    message: 'La taquilla debe tener 3 dígitos'
})
extend('tripleCero', {
    validate(value) {
    return value !== '000';
  },
  message: 'La taquilla no puede ser 000'
})
extend('primerDigito', {
  validate(value) {
    return value.charAt(0) === '0';
  },
  message: 'La taquilla debe empezar con 0'
});

export default {
    name: 'Taquillas',
    components: {
        ValidationObserver,
        ValidationProvider,
    },

    data() {
        return {
            roles: Role,
            userRoles: {},
            puntoDeVenta: null,
            bodegaDelUsuario: '',
            usuario: '',
            dialog: false,
            crearTaquilla: {
                codigoTaquilla: '',
            },
            eliminado: false,
            loading: false,
            headersTaquillas: [
                { text: "TAQUILLA", align: "center", sortable: false, width: "10rem" },
                { text: "ESTADO", sortable: false, width: "7rem" },
                { text: "ACCIONES", align: "center", sortable: false, width: "10rem" },
            ],
            listaDeTaquillas: [],
            taquillaSeleccionada: {},
            dialogoCambiarEstado: false,
            dialogoCambiarTaquilla: false,
            mensajeEstado: '',
            mensajeTaquilla: '',
            validarExistencia: '',
            validacionActiva: false,
        }
    },

    // Se inicializa la variable userRoles con los roles del usuario autenticado
    created() {
        this.usuario = this.auth.username.toUpperCase();
        this.userRoles = this.auth.roles;
        this.consultarPuntoDeVenta();
    },

    computed: {
        ...mapState(["auth", "notify", "busy", "enterprise"]),
    },

    mounted() {
        this.usuario = this.auth.username.toUpperCase();
    },

    methods: {
        ...mapMutations([
            "updateAuth",
            "hideNotify",
            "showSuccess",
            "showBusy",
            "hideBusy",
        ]),

        /**
         * Valida que solo se ingresen caracteres númericos en un campo de texto.
         *
         * @param event El evento de teclado que activa esta función.
         * @description Si se presiona una tecla que no sea número, evita que se registre en el campo.
         */
        validarLetra(event) {
            const teclaPresionada = event.key
            if (teclaPresionada.match(/[^0-9]/)) {
                event.preventDefault();
            }
        },

        /**
         * Consulta el punto de venta asociado al usuario actual.
         * Utiliza la API para obtener información sobre el punto de venta y la bodega del usuario.
         * Actualiza las propiedades 'puntoDeVenta' y 'bodegaDelUsuario' con los resultados.
         * Invoca el método 'listarTaquillas' para actualizar la lista de taquillas asociadas al usuario.
         * Este método se llama al cargar la página para obtener información inicial del punto de venta.
         */
        consultarPuntoDeVenta() {
            const usuario = this.usuario;
            this.$http.get(`msa-administration/bodegas/puntoDeVenta`, {
                params: {
                    usuario: usuario.toLowerCase(),
                    idEmpresa: this.enterprise.code
                },
            }).then( response => {
                this.puntoDeVenta = response.data[0].puntoVenta.puntoVentaId.codigoPuntoVenta;
                this.bodegaDelUsuario = response.data[0].nombreBodega;
                this.listarTaquillas();
            }).catch(error => {
                console.log(error)
                this.puntoDeVenta = '';
            })
        },

        /**
         * Obtiene la lista de las taquillas registradas mediante una solicitud HTTP GET al servidor.
         * Además, se controla el estado de carga durante la solicitud.
         */
        listarTaquillas() {
            this.loading = true;
            this.$http.get(`msa-administration/taquilla/listar`, {
                params: {
                    usuario: this.usuario.toLowerCase(),
                    puntoVenta: this.puntoDeVenta,
                },
            }).then( response => {
                this.listaDeTaquillas = response.data;
                this.loading = false;
            }).catch(error => {
                console.log(error)
                this.loading = false;
            })
        },

        /**
         * Valida en PostgreSQL.
         * Valida si el número de la taquilla ingresada existe.
         * Realiza una solicitud al servidor para verificar la unicidad de la taquilla.
         * Actualiza las propiedades 'validarExistencia' y 'validacionActiva' en respuesta al resultado de la validación.
         */
        validarTaquilla(){
            if (this.puntoDeVenta !== '' && this.crearTaquilla.codigoTaquilla !== '') {
                this.$http.get(`msa-administration/taquilla/validarTaquilla?codigoTaquilla=${this.crearTaquilla.codigoTaquilla}&puntoVenta=${this.puntoDeVenta}&idEmpresa=${this.enterprise.code}`)
                .then((response) => {
                    if (response.data !== "La taquilla no existe") {
                        this.validarExistencia = "La taquilla ya existe";
                        this.validacionActiva = true;
                    } else {
                        this.validarExistencia = "";
                        this.validacionActiva = false;
                    }
                }).catch((error) => {
                    console.log(error);
                    this.validarExistencia = "Error en la validación";
                    this.validacionActiva = true;
                })
            } else {
                this.validarExistencia = "";
                this.validacionActiva = false;
            }
        },

        /**
         * Guarda la información de una nueva taquilla en el servidor.
         * Crea un objeto con los datos de la taquilla, incluyendo la fecha de creación actual,
         * y realiza una solicitud al servidor para registrar la taquilla. Luego, actualiza la lista
         * de las taquilla y restablece el formulario.
         */
        guardarTaquilla() {
            const horaActual = new Date;
            const datos = {
                idTaquilla: null,
                puntoVenta: {
                    puntoVentaId: {
                        idEmpresa: this.enterprise.code,
                        codigoPuntoVenta: this.puntoDeVenta
                    }
                },
                codigoTaquilla: this.crearTaquilla.codigoTaquilla,
                createDate: horaActual.toISOString(),
                createdby: this.usuario.toLowerCase(),
                eliminado: this.eliminado,
            }
            this.$http.post("msa-administration/taquilla/crear", datos)
            .then(() => {
                this.listarTaquillas();
                this.reset();
            })
            .catch((error) => {
                console.log(error);
            })
        },

        /**
         * Abre el diálogo para cambiar el estado de un registro.
         * Establece la propiedad 'dialogoCambiarEstado' en true para mostrar el diálogo.
         * Guarda el registro seleccionado en la propiedad 'taquillaSeleccionada'.
         * Este método se invoca cuando se desea cambiar el estado de un registro.
         * @param {object} item - El registro seleccionado.
         */
        abrirDialogoEstado(item) {
            this.dialogoCambiarEstado = true
            this.taquillaSeleccionada = item;
            this.mensajeEstado = this.taquillaSeleccionada.eliminado === false ? '¿Desea inactivar la taquilla?' : '¿Desea activar la taquilla?';
            setTimeout(() => {
                this.mensajeEstado = this.taquillaSeleccionada.eliminado === false ? '¿Desea inactivar la taquilla?' : '¿Desea activar la taquilla?';
            }, 4000);
        },

        /**
         * Cambia el estado de un registro.
         * Actualiza el estado del registro seleccionado ('taquillaSeleccionada') según la lógica definida.
         * Realiza una solicitud HTTP PUT al servidor para actualizar el estado del registro.
         * Después de la actualización exitosa, se registra el mensaje y se actualiza la lista de registros.
         */
        cambiarEstado() {
            this.taquillaSeleccionada.eliminado = (this.taquillaSeleccionada.eliminado == false) ? true : false;
            const horaActual = new Date;
            const cambiarEstado = {
                puntoVenta: {
                    puntoVentaId: {
                        idEmpresa: this.enterprise.code,
                        codigoPuntoVenta: this.puntoDeVenta
                    }
                },
                codigoTaquilla: this.taquillaSeleccionada.codigoTaquilla,
                idTaquilla: this.taquillaSeleccionada.idTaquilla,
                eliminado: this.taquillaSeleccionada.eliminado,
                lastmodifieddate: horaActual.toISOString(),
                lastmodifiedby: this.usuario.toLowerCase(),
            }
            this.$http
                .put(`msa-administration/taquilla/cambiarEstado`, cambiarEstado)
                .then(() => {
                    this.listarTaquillas();
                    this.dialogoCambiarEstado = false;
                }).catch(error => {
                    console.log(error);
                });
        },

        /**
         * Abre el diálogo para cambiar la relación de un usuario con una taquilla seleccionada.
         * Establece la propiedad 'dialogoCambiarTaquilla' en true para mostrar el diálogo.
         * Guarda el registro seleccionado en la propiedad 'taquillaSeleccionada'.
         * Este método se invoca cuando el usuario desea cambiar su relación a otra taquilla.
         * @param {object} item - El registro seleccionado.
         */
        abrirDialogoTaquilla(item) {
            this.dialogoCambiarTaquilla = true
            this.taquillaSeleccionada = item;
            if (this.taquillaSeleccionada.idUsta === null) {
            this.mensajeTaquilla = '¿Está seguro de cambiar al usuario ' + this.taquillaSeleccionada.usuario.toUpperCase() + ' a la taquilla ' + this.taquillaSeleccionada.codigoTaquilla + '?' ;
            } else {
                this.mensajeTaquilla = '';
            }
        },

        /**
         * Cambia el estado de una relación de un usuario con una taquilla seleccionada.
         * Actualiza la relación con la ('taquillaSeleccionada') según la lógica definida.
         * Realiza una solicitud HTTP PUT al servidor para cambiar la relación.
         * Después de la actualización exitosa, se registra el mensaje y se actualiza la lista de registros.
         */
        cambiarTaquilla() {
            const taquillaConIdUsta = this.listaDeTaquillas.find(taquilla => taquilla.idUsta !== null);
            this.taquillaSeleccionada.idUsta = taquillaConIdUsta ? taquillaConIdUsta.idUsta : null;
            const horaActual = new Date;
            const cambiarTaquilla = {
                empresa: { idEmpresa: this.enterprise.code },
                idUsta: this.taquillaSeleccionada.idUsta,
                taquilla: {
                    idTaquilla: this.taquillaSeleccionada.idTaquilla,
                    codigoTaquilla: this.taquillaSeleccionada.codigoTaquilla,
                },
                usuarios: {
                    usuario: this.taquillaSeleccionada.usuario,
                },
                createdby: this.usuario.toLowerCase(),
                createDate: horaActual.toISOString(),
                eliminado: this.eliminado,
            }
            this.$http
                .put(`msa-administration/usuarioTaquilla/cambiarTaquilla`, cambiarTaquilla)
                .then(() => {
                    this.listarTaquillas();
                    this.dialogoCambiarTaquilla = false;
                }).catch(error => {
                    console.log(error);
                });
        },

        /**
         * Restablece los valores de los campos y las variables relacionadas a la taquilla.
         * Establece los valores predeterminados y cierra los cuadros de diálogo abiertos.
         * Reinicia el estado de edición y activación de campos.
         * Reinicia el estado del componente de observación (refs.observer).
         */
        reset() {
            this.dialog = false;
            this.crearTaquilla.codigoTaquilla = '';
            this.taquillaSeleccionada = {};
            this.validarExistencia = "";
            this.validacionActiva = false;
            this.dialogoCambiarEstado = false;
            this.dialogoCambiarTaquilla = false;
            this.mensajeEstado = '';
            this.mensajeTaquilla=  '';
            this.$refs.observer.reset();
        },
    },
}
</script>


<!-- #################################################################################### -->
<!-- ###### Sección de Estilos                                                     ###### -->
<!-- #################################################################################### -->
<style scoped>
.fondoPuntoVenta {
  background-color: #2b6fd4;
  color: white;
  width: 100%;
  padding: 0% !important;
  border-radius: 0.35rem;
}

.fondoPuntoVenta span{
    font-size: medium;
    width: 100%;
    text-align: center;
}

.fondoDialog {
  background-color: #1867c0;
  color: white;
}

.formulario-flex {
  flex-wrap: nowrap;
}

@media (max-width: 700px) {
  .formulario-flex {
    flex-wrap: wrap !important;
  }
}

::v-deep .elevation div table thead tr th {
  background-color: rgb(223, 223, 223) !important;
}

::v-deep .elevation div table thead tr th span {
  font-weight: bold;
  color: black !important;
}

::v-deep .elevation .v-data-footer {
  width: 100%;
}

::v-deep .elevation .v-data-footer__select .v-select {
  margin: 5px;
  margin-left: 10px;
}

.tooltip {
    height: 2.5rem;
    margin-left: 3px;
}

.cambioCaja {
    font-size: 16px;
    color: #0009;
}
</style>